import * as React from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const TimelinePage = () => {
  return (
    <Layout pageSentence="Here's my" pageTitle="Timeline" active="timeline">
      
    </Layout>
  )
}

export default TimelinePage